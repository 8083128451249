import React from 'react';

import { BlogHomepageData, TopicCluster } from '@ha/contentful';

import { useIntl } from 'ha/i18n';
import { getTopicClusterLabelById } from 'ha/modules/Blog/utils';

interface Props {
  topicClusters: TopicCluster[];
  articles: BlogHomepageData[];
}

const useTopicClusters = ({ topicClusters = [], articles = [] }: Props) => {
  const [selectedTopicIds, setSelectedTopicIds] = React.useState<
    TopicCluster[]
  >([]);
  const { T } = useIntl();

  const [isSelectedAll, setSelectedAll] = React.useState(true);

  const handleSelectionTopic = React.useCallback((id: TopicCluster) => {
    setSelectedTopicIds(prevSelectedIds => {
      const updatedList = prevSelectedIds.includes(id)
        ? prevSelectedIds.filter(item => item !== id)
        : [...prevSelectedIds, id];
      setSelectedAll(!updatedList.length);
      return updatedList;
    });
  }, []);

  const handleSelectionAll = React.useCallback(() => {
    setSelectedAll(true);
    setSelectedTopicIds([]);
  }, []);

  const topics = React.useMemo(
    () =>
      topicClusters.map(id => ({
        label: T(getTopicClusterLabelById(id)),
        isSelected: selectedTopicIds.includes(id),
        handleSelection: () => handleSelectionTopic(id),
      })),
    [topicClusters, T, selectedTopicIds, handleSelectionTopic],
  );

  const filteredArticles = React.useMemo(
    () =>
      isSelectedAll
        ? articles
        : articles.filter(
            ({ topicCluster }) =>
              !!topicCluster && selectedTopicIds.includes(topicCluster),
          ),
    [articles, isSelectedAll, selectedTopicIds],
  );

  return {
    topics,
    filteredArticles,
    isSelectedAll,
    handleSelectionAll,
  };
};

export { useTopicClusters };
